<template>
  <div class="page-content">
    <breadcrumb title="Unterkünfte" bgImage="/assets/bg/unterkunft.jpg" :hideOnMobile="true"></breadcrumb>
    <section class="section">
      <div class="container-fluid">
        <div class="row">
          <UnterkunftsBuchung />
        </div>
      </div>
    </section>

    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>   
 import { showLoader, hideLoader } from '@/utils/helpers';
 import { collections4Tripmeister } from '@/api/custom';

 export default {
  name: 'Unterkuenfte',
  components: {   
    Breadcrumb: () => import('@/components/layout/Breadcrumb'),
    UnterkunftsBuchung: () => import('@/components/secra/unterkunftsBuchung.vue'),
  },
  data() {
    return {
      loader: false,
      collections: null,
    }
  },
  computed:{

  },
  created(){
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  @import '@/scss/_mixins.scss';

  .breadcrumb {
    background-position: center;
  }

</style>
